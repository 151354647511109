//React
import { useState, useEffect } from 'react';

//System
import { register } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Styles
import './styles.css';

//Events
const onClickChange = () => {
	(async () => {
		const username = document.getElementById('username').value;
		const newPasswordHash = document.getElementById('newPasswordHash').value;

		const res = await iwdGet('changePassword', {
			username,
			newPasswordHash
		});

		if (res.success)
			performBack();
	})();
};

//Components
const ChangePassword = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'changePassword', setter, state), []);

	if (!state.vis)
		return;

	return (
		<div className='cpnChangePassword centered'>
			<div className='title'>
				Change Password
			</div>
			<div className='bottom'>
				<input id='username' placeholder='username...' />
				<input id='newPasswordHash' placeholder='hashed password...' />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
				<div className='btn right' onClick={onClickChange}>
					Change
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
