//React
import { useState, useEffect } from 'react';

//System
import { register, getState, setState } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Styles
import './styles.css';

//Events
const onRefresh = (forceRefresh = false) => {
	const { needsRefresh, selected } = getState('patreonLog');

	if (!needsRefresh && !forceRefresh)
		return;

	const { username } = selected;

	(async () => {
		const query = { username };

		const { list } = (await iwdGet('mtPatreonProcessLog', query));

		const mappedList = list
			.map(({ id, value: { date, msg } }) => {
				return {
					id,
					date,
					msg
				};
			})
			.sort((a, b) => new Date(b.date) - new Date(a.date));

		setState('patreonLog', {
			list: mappedList,
			needsRefresh: false
		});
	})();
};

//Components
const List = ({ state: { list, needsRefresh } }) => {
	if (!list || needsRefresh)
		return null;

	const res = list
		.map((l, i) => {
			const { id, date, msg } = l;

			const d = new Date(date);

			/* eslint-disable-next-line max-len */
			const dateString = `${d.getFullYear()}/${d.getMonth()}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;

			const key = `entry-${i}`;

			return (
				<div
					key={key}
					className='row'
				>
					<div className='entry narrow'>{dateString}</div>
					<div className='entry'>{id}</div>
					<div className='entry wide'>{msg}</div>
				</div>
			);
		});

	return (
		<div className='list'>
			{res}
		</div>
	);
};

const PatreonLog = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'patreonLog', setter, state), []);
	useEffect(onRefresh, [state.needsRefresh]);

	if (!state.vis)
		return;

	let title = `Patreon Log (${state.selected.username})`;

	return (
		<div className='cpnPatreonLog centered'>
			<div className='title'>{title}</div>
			<div className='bottom'>
				<div className='headings'>
					<div className='entry narrow'>Date</div>
					<div className='entry'>ID</div>
					<div className='entry wide'>Msg</div>
				</div>
				<List state={state} />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
			</div>
		</div>
	);
};

export default PatreonLog;
