const states = {};
const setters = {};

const register = (id, setter, state) => {
	states[id] = state;
	setters[id] = setter;
};

const getState = id => {
	return states[id] ?? {};
};

const setState = (id, state) => {
	setters[id](prev => {
		const newState = Object.assign(
			JSON.parse(JSON.stringify(prev)),
			state
		);

		states[id] = newState;

		return newState;
	});
};

export {
	register,
	getState,
	setState
};
