//React
import { useState, useEffect } from 'react';

//System
import { register, getState, setState } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Styles
import './styles.css';

//Events

/* eslint-disable-next-line max-lines-per-function */
const onRefresh = (forceRefresh = false) => {
	const { needsRefresh, selected, limit, offset } = getState('chatLog');

	if (!needsRefresh && !forceRefresh)
		return;

	const { username, name } = selected;

	(async () => {
		const query = {};

		if (!!username)
			query.username = username;
		if (!!name)
			query.name = name;
		if (!!offset)
			query.offset = offset;
		if (!!limit)
			query.limit = limit;

		const { list, hasMorePages } = (await iwdGet('mtChatLog', query));

		let mappedList = list
			.map(({ date, msg }) => {
				return {
					date,
					msg
				};
			});

		setState('chatLog', {
			list: mappedList,
			needsRefresh: false,
			hasMorePages
		});
	})();
};

const onSetLimit = ({ target }) => {
	const limit = Math.max(1, Math.min(100, ~~target.value));

	setState('chatLog', {
		limit,
		offset: 0
	});
};

const onSetOffset = delta => {
	const { offset, limit, hasMorePages } = getState('chatLog');

	if (!hasMorePages && delta > 1)
		return;

	const newOffset = Math.max(0, offset + (delta * limit));

	setState('chatLog', {
		offset: newOffset,
		needsRefresh: true
	});
};

//Components
const List = ({ state: { list, needsRefresh } }) => {
	if (!list || needsRefresh)
		return null;

	const res = list
		.map((l, i) => {
			const { date, msg } = l;

			const d = new Date(date);

			/* eslint-disable-next-line max-len */
			const dateString = `${d.getFullYear()}/${d.getMonth()}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;

			const key = `entry-${i}`;

			return (
				<div
					key={key}
					className='row'
				>
					<div className='entry narrow'>{dateString}</div>
					<div className='entry wide'>{msg}</div>
				</div>
			);
		});

	return (
		<div className='list'>
			{res}
		</div>
	);
};

/* eslint-disable-next-line max-lines-per-function */
const ChatLog = () => {
	const [state, setter] = useState({
		vis: false,
		offset: 0,
		limit: 10
	});

	useEffect(register.bind(null, 'chatLog', setter, state), []);
	useEffect(onRefresh, [state.needsRefresh]);

	const { vis, offset, limit, hasMorePages, selected } = state;

	if (!vis)
		return;

	let title = 'Chat Log (';
	if (selected.name)
		title += `Character: ${selected.name})`;
	else if (selected.username)
		title += `Username: ${selected.username})`;

	const btnPrevClasses = `btn right${offset > 0 ? '' : ' disabled'}`;
	const btnNextClasses = `btn${hasMorePages ? '' : ' disabled'}`;

	return (
		<div className='cpnChatLog centered'>
			<div className='title'>{title}</div>
			<div className='bottom'>
				<div className='headings'>
					<div className='entry narrow'>Date</div>
					<div className='entry wide'>Msg</div>
				</div>
				<List state={state} />
			</div>
			<div className='toolbar'>
				<div className='label'>Entries per page:</div>
				<input
					id='limit'
					placeholder='limit...'
					defaultValue={limit}
					onChange={onSetLimit}
					type='number'
					min={1}
					max={100}
				/>
				<div className={btnPrevClasses} onClick={onSetOffset.bind(null, -1)}>&lt;</div>
				<div className='label'>{`Offset: ${offset}`}</div>
				<div className={btnNextClasses} onClick={onSetOffset.bind(null, 1)}>&gt;</div>
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
				<div className='btn right' onClick={onRefresh.bind(null, true)}>
					Refresh
				</div>
			</div>
		</div>
	);
};

export default ChatLog;
