//React
import { useState, useEffect } from 'react';

//System
import { register, getState, setState } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Styles
import './styles.css';

//Events
const onRefresh = (forceRefresh = false) => {
	const { needsRefresh, selected } = getState('modLog');

	if (!needsRefresh && !forceRefresh)
		return;

	const { username, name } = selected;

	(async () => {
		const query = {};
		if (!!username)
			query.username = username;
		if (!!name)
			query.name = name;
		const list = (await iwdGet('mtModLog', query))
			.map(({ id: date, value: { command: action, reason, source } }) => {
				return {
					date,
					source,
					action,
					reason
				};
			})
			.sort((a, b) => new Date(b.date) - new Date(a.date));

		setState('modLog', {
			list,
			needsRefresh: false
		});
	})();
};

//Components
const List = ({ state: { list, needsRefresh } }) => {
	if (!list || needsRefresh)
		return null;

	const res = list
		.map((l, i) => {
			const { date, action, reason, source } = l;

			const d = new Date(date);

			/* eslint-disable-next-line max-len */
			const dateString = `${d.getFullYear()}/${d.getMonth()}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;

			const key = `entry-${i}`;

			return (
				<div
					key={key}
					className='row'
				>
					<div className='entry hideMobile'>{dateString}</div>
					<div className='entry hideMobile'>{source}</div>
					<div className='entry'>{action}</div>
					<div className='entry'>{reason}</div>
				</div>
			);
		});

	return (
		<div className='list'>
			{res}
		</div>
	);
};

/* eslint-disable-next-line max-lines-per-function */
const ModLog = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'modLog', setter, state), []);
	useEffect(onRefresh, [state.needsRefresh]);

	if (!state.vis)
		return;

	let title = 'Moderation Log (';
	if (state.selected.name)
		title += `Character: ${state.selected.name})`;
	else if (state.selected.username)
		title += `Username: ${state.selected.username})`;

	return (
		<div className='cpnModLog centered'>
			<div className='title'>{title}</div>
			<div className='bottom'>
				<div className='headings'>
					<div className='entry hideMobile'>Date</div>
					<div className='entry hideMobile'>Source</div>
					<div className='entry'>Action</div>
					<div className='entry'>Reason</div>
				</div>
				<List state={state} />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
				<div className='btn right' onClick={onRefresh.bind(null, true)}>
					Refresh
				</div>
			</div>
		</div>
	);
};

export default ModLog;
