//Helpers
import iwdGet from './iwdGet';

const lookupUser = async username => {
	const response = await iwdGet('mtLookupUser', { username });

	return response;
};

export default lookupUser;
