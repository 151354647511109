//System
import { setState } from '../system/stateManager';

//Helpers
import { setup as setupBackButton } from './backButton';

//Helperr
const swapVis = (toHide, toShow) => {
	setState(toHide, { vis: false });

	setState(toShow, { vis: true });

	setupBackButton(toHide, toShow);
};

export const swapVisExtra = (toHide, toShow, extraStates) => {
	setState(toHide, { vis: false });

	setState(toShow, {
		vis: true,
		...extraStates
	});

	setupBackButton(toHide, toShow);
};

export default swapVis;
