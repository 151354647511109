//React
import { useState, useEffect } from 'react';

//System
import { register } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Styles
import './styles.css';

//Events
const onClickSend = clearAfterSend => {
	(async () => {
		const msg = document.getElementById('bla').value;

		await iwdGet('broadcast', { msg });

		if (clearAfterSend)
			document.getElementById('bla').value = '';
	})();
};

//Components
const Broadcast = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'broadcast', setter, state), []);

	if (!state.vis)
		return;

	return (
		<div className='cpnBroadcast centered'>
			<div className='title'>
				Broadcast
			</div>
			<div className='bottom'>
				<textarea
					id='bla'
					placeholder='type a message...'
					rows={5}
				/>
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
				<div className='btn right' onClick={onClickSend.bind(null, false)}>
					Send
				</div>
				<div className='btn' onClick={onClickSend.bind(null, true)}>
					Send & Clear
				</div>
			</div>
		</div>
	);
};

export default Broadcast;
