//React
import { useState, useEffect } from 'react';

//System
import { register } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//System
import { getState, setState } from '../../system/stateManager';

//Styles
import './styles.css';

//Events
const onClickAction = () => {
	(async () => {
		const { action, query, needWeeks } = getState('reasonAction');

		const reason = document.getElementById('reason').value;

		setState('reasonAction', { needWeeks: false });

		const payload = {
			reason,
			...query
		};

		if (needWeeks) {
			payload.weeks = +document.getElementById('weeks').value;
			if (payload.weeks === 0)
				payload.weeks = -1;
		}

		const res = await iwdGet(action, payload);

		if (res.success)
			performBack();
	})();
};

//Components
const ReasonAction = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'reasonAction', setter, state), []);

	if (!state.vis)
		return;

	const weeks = state.needWeeks ? <input id='weeks' placeholder='weeks...' /> : null;

	return (
		<div className='cpnChangePassword centered'>
			<div className='title'>
				{state.actionName}
			</div>
			<div className='bottom'>
				<input id='reason' placeholder='reason...' />
				{weeks}
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>Back</div>
				<div className='btn right' onClick={onClickAction}>
					{state.actionName}
				</div>
			</div>
		</div>
	);
};

export default ReasonAction;
