//React
import { useState, useEffect } from 'react';

//System
import { register, setState, getState } from '../../system/stateManager';

//System Helpers
import { setup as setupBackButton } from '../../helpers/backButton';
import swapVis from '../../helpers/swapVis';
import lookupUser from '../../helpers/lookupUser';
import lookupCharacter from '../../helpers/lookupCharacter';

//Styles
import './styles.css';

//Helpers
const navigateToUserInfo = lookupUsername => {
	setupBackButton('app', 'userInfo');

	setState('login', { vis: false });

	(async () => {
		const selected = await lookupUser(lookupUsername);

		setState('userInfo', {
			vis: true,
			selected,
			data: null
		});
	})();
};

const navigateToCharInfo = name => {
	setupBackButton('app', 'characterInfo');

	setState('login', { vis: false });

	(async () => {
		const selected = await lookupCharacter(name);

		setState('characterInfo', {
			vis: true,
			selected,
			data: null
		});
	})();
};

//Events
const onClickLogin = () => {
	const username = document.getElementById('username').value;
	const password = document.getElementById('password').value;

	setState('login', {
		username,
		password
	});

	/* eslint-disable-next-line max-len */
	const { urlParams: { lookupUser: lookupUsername, lookupCharacter: lookupCharname } } = getState('system');

	if (lookupUsername) {
		navigateToUserInfo(lookupUsername);

		return;
	} else if (lookupCharname) {
		navigateToCharInfo(lookupCharname);

		return;
	}

	swapVis('login', 'app');
};

//Components
const Login = () => {
	const [state, setter] = useState({ vis: true });

	useEffect(register.bind(null, 'login', setter, state), []);

	if (!state.vis)
		return;

	return (
		<div className='cpnLogin centered'>
			<div className='title'>
				Login
			</div>
			<div className='bottom'>
				<input id='username' placeholder='username...' />
				<input
					id='password'
					type='password'
					placeholder='password...'
				/>
			</div>
			<div className='toolbar'>
				<div className='btn right' onClick={onClickLogin}>
					Login
				</div>
			</div>
		</div>
	);
};

export default Login;
