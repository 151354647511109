///React
import { useState, useEffect } from 'react';

//System
import { register, setState } from '../../system/stateManager';

//System Helpers
import { setup as setupBackButton, performBack } from '../../helpers/backButton';

//Helpers
import lookupUser from '../../helpers/lookupUser';

//Styles
import './styles.css';

//Events
const onClickLookup = () => {
	(async () => {
		const username = document.getElementById('lookupUserUsername').value;

		const selected = await lookupUser(username);
		if (selected.success === false)
			return;

		setupBackButton('lookupUser', 'userInfo');

		setState('lookupUser', { vis: false });

		setState('userInfo', {
			vis: true,
			selected,
			data: null
		});
	})();
};

//Components
const LookupUser = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'lookupUser', setter, state), []);

	if (!state.vis)
		return;

	return (
		<div className='cpnLookupUser centered'>
			<div className='title'>
				Lookup User
			</div>
			<div className='bottom'>
				<input id='lookupUserUsername' placeholder='username...' />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
				<div className='btn right' onClick={onClickLookup}>
					Lookup
				</div>
			</div>
		</div>
	);
};

export default LookupUser;
