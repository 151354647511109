/* eslint-disable max-lines */

//React
import { useState, useEffect } from 'react';

//System
import { register, setState, getState } from '../../system/stateManager';

//System Helpers
import { setup as setupBackButton, performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Styles
import './styles.css';

//Events
const onGetInfo = () => {
	const { selected, data, vis } = getState('characterInfo');

	if (!selected || data || !vis)
		return;

	(async () => {
		const res = await iwdGet('mtLookupCharacter', { name: selected.name });

		setState('characterInfo', { data: res });
	})();
};

const onClickMute = async () => {
	const { selected: { name } } = getState('characterInfo');

	setupBackButton('characterInfo', 'reasonAction');

	setState('characterInfo', {
		vis: false,
		data: null
	});

	setState('reasonAction', {
		vis: true,
		actionName: 'Mute',
		action: 'mtMute',
		query: { name }
	});
};

const onClickUnmute = async () => {
	const { selected: { name } } = getState('characterInfo');

	setupBackButton('characterInfo', 'reasonAction');

	setState('characterInfo', {
		vis: false,
		data: null
	});

	setState('reasonAction', {
		vis: true,
		actionName: 'Unmute',
		action: 'mtUnmute',
		query: { name }
	});
};

const onClickModLog = async () => {
	const { selected: { name } } = getState('characterInfo');

	setupBackButton('characterInfo', 'modLog');

	setState('characterInfo', { vis: false });

	setState('modLog', {
		vis: true,
		selected: {
			name,
			username: null
		},
		needsRefresh: true
	});
};

const onClickInventory = async () => {
	const { data: { inventory } } = getState('characterInfo');

	setupBackButton('characterInfo', 'inventory');

	setState('characterInfo', { vis: false });

	setState('inventory', {
		vis: true,
		title: 'Inventory',
		items: inventory
	});
};

const onClickChatLog = async () => {
	const { selected: { name } } = getState('characterInfo');

	setupBackButton('characterInfo', 'chatLog');

	setState('characterInfo', { vis: false });

	setState('chatLog', {
		vis: true,
		selected: {
			name,
			username: null
		},
		needsRefresh: true
	});
};

const onClickUsername = () => {
	const { selected: { username, ipHash } } = getState('characterInfo');

	setupBackButton('characterInfo', 'userInfo');

	setState('characterInfo', {
		vis: false,
		data: null
	});

	setState('userInfo', {
		vis: true,
		selected: {
			username,
			ipHash
		},
		data: null
	});
};

//Components
const HighestKillStreak = () => {
	const { data } = getState('characterInfo');
	if (!data)
		return null;

	const { stats: { stats: { mobKillStreaks } } } = data;

	const entries = Object.entries(mobKillStreaks)
		.sort((a, b) => a[1] - b[1]);

	if (entries.length === 0)
		return null;

	const value = `${(entries[0][1] + '').substr(0, 4)}% ${entries[0][0]}`;

	return (
		<>
			<div className='entry'>
				<div className='label'>Kill Streak</div>
				<div className='value'>{value}</div>
			</div>
		</>
	);
};

/* eslint-disable-next-line max-lines-per-function */
const Info = () => {
	const { data } = getState('characterInfo');
	if (!data)
		return null;

	const { username, stats, zoneName } = data;
	const { values: { level } } = stats;

	return (
		<>
			<div className='entry'>
				<div className='label'>Username</div>
				<div className='value clickable' onClick={onClickUsername}>{username}</div>
			</div>
			<div className='entry'>
				<div className='label'>Level</div>
				<div className='value'>{level}</div>
			</div>
			<div className='entry'>
				<div className='label'>Zone</div>
				<div className='value'>{zoneName}</div>
			</div>
			<HighestKillStreak />
		</>
	);
};

const CharacterInfo = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'characterInfo', setter, state), []);
	useEffect(onGetInfo, [state.selected?.name, state.vis]);

	if (!state.vis)
		return;

	const btnMuteClasses = `btn${state.data?.isMuted ? ' disabled' : ''}`;
	const btnUnmuteClasses = `btn${!state.data?.isMuted ? ' disabled' : ''}`;

	return (
		<div className='cpnCharacterInfo centered'>
			<div className='title'>
				Character Info:
				{' '}
				{state.selected?.name}
			</div>
			<div className='bottom'>
				<Info />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>Back</div>
				<div className={btnMuteClasses} onClick={onClickMute}>Mute</div>
				<div className={btnUnmuteClasses} onClick={onClickUnmute}>Unmute</div>
				<div className='btn' onClick={onClickInventory}>Inventory</div>
				<div className='btn' onClick={onClickModLog}>Mod Log</div>
				<div className='btn' onClick={onClickChatLog}>Chat Log</div>
			</div>
		</div>
	);
};

export default CharacterInfo;
