//React
import { useState, useEffect } from 'react';

//System
import { register, setState, getState } from '../../system/stateManager';

//System Helpers
import { setup as setupBackButton, performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Styles
import './styles.css';

//Events
const onClickRefresh = (forceRefresh = false) => {
	const { needsRefresh } = getState('online');
	if (!needsRefresh && !forceRefresh)
		return;

	(async () => {
		let { list } = await iwdGet('onlineList');

		list = list.sort((a, b) => {
			if (a.name.toLowerCase() < b.name.toLowerCase())
				return -1;
			else if (a.name.toLowerCase() > b.name.toLowerCase())
				return 1;

			return 0;
		});

		setState('online', {
			list,
			needsRefresh: false
		});
	})();
};

const onClickUser = selected => {
	setupBackButton('online', 'userInfo');

	setState('online', { vis: false });

	setState('userInfo', {
		vis: true,
		selected,
		data: null
	});
};

const onClickCharacter = selected => {
	setupBackButton('online', 'characterInfo');

	setState('online', { vis: false });

	setState('characterInfo', {
		vis: true,
		selected,
		data: null
	});
};

/* eslint-disable-next-line max-lines-per-function */
const List = ({ ipHash, list, listOrdered }) => {
	const res = list
		.filter(l => l.ipHash === ipHash)
		.map((l, i) => {
			listOrdered.push(l);

			const { username, name, zoneName, level, isMuted } = l;

			const key = `entry-${i}`;

			const isMutedText = isMuted ? 'yes' : '';

			let className = 'row';
			if (listOrdered.length % 2 === 0)
				className += ' even';
			else
				className += ' odd';

			return (
				<div
					key={key}
					className={className}
				>
					<div className='entry clickable wide' onClick={onClickCharacter.bind(null, l)}>
						{name}
					</div>
					<div className='entry clickable wide' onClick={onClickUser.bind(null, l)}>
						{username}
					</div>
					<div className='entry hideMobile narrow'>{level}</div>
					<div className='entry hideMobile'>{zoneName}</div>
					<div className='entry narrow'>{isMutedText}</div>
				</div>
			);
		});

	return (
		<div className='list'>
			{res}
		</div>
	);
};

//Components
const IpHashGroups = ({ state: { list = [] } }) => {
	const ipHashGroups = [];
	list.forEach(({ ipHash }) => {
		if (!ipHashGroups.includes(ipHash))
			ipHashGroups.push(ipHash);
	});

	const listOrdered = [];

	return ipHashGroups.map((g, i) => {
		const key = `entry-${i}`;

		const ipHashText = g.substr(0, 10);

		return (
			<div key={key} className='ipHashGroup'>
				<div className='ipHash hideMobile'>{ipHashText}</div>
				<List
					ipHash={g}
					list={list}
					listOrdered={listOrdered}
				/>
			</div>
		);
	});
};

/* eslint-disable-next-line max-lines-per-function */
const Online = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'online', setter, state), [setter]);
	useEffect(onClickRefresh, [state.needsRefresh]);

	if (!state.vis)
		return;

	return (
		<div className='cpnOnline centered'>
			<div className='title'>
				Online Players
			</div>
			<div className='bottom'>
				<div className='headings'>
					<div className='entry hideMobile'>IP Hash</div>
					<div className='entry wide'>Name</div>
					<div className='entry wide'>Username</div>
					<div className='entry hideMobile narrow'>Level</div>
					<div className='entry hideMobile'>Zone</div>
					<div className='entry narrow'>Muted</div>
				</div>
				<div className='list'>
					<IpHashGroups state={state} />
				</div>
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
				<div className='btn right' onClick={onClickRefresh.bind(null, true)}>
					Refresh List
				</div>
			</div>
		</div>
	);
};

export default Online;
