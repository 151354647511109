const sortAlphabetical = (a, b) => {
	if (a.toLowerCase() < b.toLowerCase())
		return -1;
	else if (b.toLowerCase() < a.toLowerCase())
		return 1;

	return 0;
};

export default sortAlphabetical;
