//React
import { useState, useEffect } from 'react';

//System
import { register } from '../../system/stateManager';

//System Helpers
import swapVis, { swapVisExtra } from '../../helpers/swapVis';

//Styles
import './styles.css';

//Components
const App = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'app', setter, state), []);

	if (!state.vis)
		return null;

	return (
		<div className='cpnApp'>
			<div className='title'>
				Modtools
			</div>
			<div className='toolbar'>
				<div className='btn' onClick={swapVis.bind(null, 'app', 'broadcast')}>
					Broadcast
				</div>
				<div className='btn' onClick={swapVis.bind(null, 'app', 'lookupCharacter')}>
					Lookup Character
				</div>
				<div className='btn' onClick={swapVis.bind(null, 'app', 'lookupUser')}>
					Lookup User
				</div>
				<div className='btn' onClick={swapVisExtra.bind(null, 'app', 'online', { needsRefresh: true })}>
					Online Players
				</div>
				<div className='btn' onClick={swapVis.bind(null, 'app', 'changePassword')}>
					Change Password
				</div>
			</div>
		</div>
	);
};

export default App;
