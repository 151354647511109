///React
import { useState, useEffect } from 'react';

//System
import { register, setState } from '../../system/stateManager';

//System Helpers
import { setup as setupBackButton, performBack } from '../../helpers/backButton';
import lookupCharacter from '../../helpers/lookupCharacter';

//Styles
import './styles.css';

//Events
const onClickLookup = () => {
	(async () => {
		const name = document.getElementById('lookupCharacterName').value;

		const selected = await lookupCharacter(name);
		if (selected.success === false)
			return;

		setupBackButton('lookupCharacter', 'characterInfo');

		setState('lookupCharacter', { vis: false });

		setState('characterInfo', {
			vis: true,
			selected,
			data: null
		});
	})();
};

//Components
const LookupCharacter = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'lookupCharacter', setter, state), []);

	if (!state.vis)
		return;

	return (
		<div className='cpnLookupCharacter centered'>
			<div className='title'>
				Lookup Character
			</div>
			<div className='bottom'>
				<input id='lookupCharacterName' placeholder='character name...' />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>
					Back
				</div>
				<div className='btn right' onClick={onClickLookup}>
					Lookup
				</div>
			</div>
		</div>
	);
};

export default LookupCharacter;
