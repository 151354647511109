//React
import React from 'react';
import ReactDOM from 'react-dom/client';

//System
import { register } from './system/stateManager';

//Components
import App from './components/app';
import Broadcast from './components/broadcast';
import ChangePassword from './components/changePassword';
import CharacterInfo from './components/characterInfo';
import ChatLog from './components/chatLog';
import Inventory from './components/inventory';
import Login from './components/login';
import LookupCharacter from './components/lookupCharacter';
import LookupUser from './components/lookupUser';
import ModLog from './components/modLog';
import Online from './components/online';
import PatreonLog from './components/patreonLog';
import ReasonAction from './components/reasonAction';
import UserInfo from './components/userInfo';

//Styles
import './index.css';

//Main
const root = ReactDOM.createRoot(document.getElementById('root'));

window.isMobile = (
	/Mobi|Android/i.test(navigator.userAgent) ||
	(
		navigator.platform === 'MacIntel' &&
		navigator.maxTouchPoints > 1
	)
);

if (window.isMobile)
	document.getElementById('root').classList.add('mobile');

const urlParams = Object.fromEntries(
	window.location.search
		.replace('?', '')
		.split('&')
		.map(s => s.split('='))
);

const systemSetter = () => {};

register('system', systemSetter, {
	isMobile,
	urlParams
});

root.render(
	<React.StrictMode>
		<App />
		<Broadcast />
		<ChangePassword />
		<CharacterInfo />
		<ChatLog />
		<Inventory />
		<Login />
		<LookupCharacter />
		<LookupUser />
		<ModLog />
		<Online />
		<PatreonLog />
		<ReasonAction />
		<UserInfo />
	</React.StrictMode>
);
