//System
import { getState } from '../system/stateManager';

//Config
import { iwdPath } from '../config';

//Helper
const iwdGet = async (action, config = {}) => {
	const { username, password } = getState('login');

	Object.assign(config, {
		authUsername: username,
		authPassword: password
	});

	let url = `${iwdPath}/${action}/?`;

	if (typeof(config) === 'object' && !!config) {
		Object.entries(config).forEach(([k, v]) => {
			url += `${k}=${v}&`;
		});

		url.substr(0, url.length - 2);
	} else
		url += `config=${config}`;

	url = encodeURI(url);

	const res = await fetch(url);

	const json = await res.json();

	return json;
};

export default iwdGet;
