//Helpers
import iwdGet from './iwdGet';

const lookupCharacter = async name => {
	const response = await iwdGet('mtLookupCharacter', { name });

	return response;
};

export default lookupCharacter;
