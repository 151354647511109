//React
import { useState, useEffect } from 'react';

//System
import { register, getState } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';

//Config
import { iwdPath } from '../../config';

//Styles
import './styles.css';

//Events

//Components

/* eslint-disable-next-line max-lines-per-function */
const Items = () => {
	const { items } = getState('inventory');

	if (!items)
		return null;

	const res = items.map((item, i) => {
		const key = `item-${i}`;

		const root = `${iwdPath}/images`;

		let spritesheet = item.spritesheet || `${root}/items.png`;
		if (!item.spritesheet) {
			if (item.material)
				spritesheet = `${root}/materials.png`;
			else if (item.quest)
				spritesheet = `${root}/questItems.png`;
			else if (item.type === 'consumable')
				spritesheet = `${root}/consumables.png`;
			else if (item.type === 'skin')
				spritesheet = `${root}/characters.png`;
		}

		const imgX = (-item.sprite[0] * 64);
		const imgY = (-item.sprite[1] * 64);

		const backgroundPosition = `${imgX}px ${imgY}px`;
		const style = {
			background: `url(${spritesheet}) no-repeat scroll ${backgroundPosition} / auto`,
			width: '64px',
			height: '64px'
		};

		const eq = item.eq ? <div className='eq'>eq</div> : null;
		const quantity = ~~item.quantity > 1 ? <div className='quantity'>{item.quantity}</div> : null;

		return (
			<div key={key} className='item'>
				<div className='sprite' style={style} />
				{quantity}
				{eq}
			</div>
		);
	});

	return res;
};

const Inventory = () => {
	const [state, setter] = useState({ vis: false });

	const { vis, title } = state;

	useEffect(register.bind(null, 'inventory', setter, state), []);

	if (!vis)
		return;

	return (
		<div className='cpnInventory centered'>
			<div className='title'>
				{title}
			</div>
			<div className='bottom'>
				<Items />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>Back</div>
			</div>
		</div>
	);
};

export default Inventory;
