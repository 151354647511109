//System
import { setState } from '../../../system/stateManager';

//System Helpers
import iwdGet from '../../../helpers/iwdGet';
import sortAlphabetical from '../../../helpers/sortAlphabetical';

//System
import { getState } from '../../../system/stateManager';

//Event
const onGetInfo = () => {
	const { selected, data, vis } = getState('userInfo');

	if (!selected || data || !vis)
		return;

	(async () => {
		const usernamesWithIp = (await iwdGet('getUsernamesWithIpHash', { ipHash: selected.ipHash }))
			.map(u => u.toString())
			.sort(sortAlphabetical);

		const characters = (await iwdGet('mtListCharactersWithUsername', { username: selected.username }))
			.map(u => u.name ?? u.toString())
			.sort(sortAlphabetical);

		const charactersWithIp = (await iwdGet('getCharactersWithIpHash', { ipHash: selected.ipHash }))
			.map(u => u.name ?? u.toString())
			.sort(sortAlphabetical);

		const res = await iwdGet('mtLookupUser', { username: selected.username });

		setState('userInfo', {
			usernamesWithIp,
			charactersWithIp,
			characters,
			data: res
		});
	})();
};

export default onGetInfo;
