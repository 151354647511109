import { setState } from '../system/stateManager';

const showIdStack = [];
const hideIdStack = [];

export const setup = (idShow, idHide) => {
	showIdStack.push(idShow);
	hideIdStack.push(idHide);
};

export const performBack = () => {
	const idShow = showIdStack.pop();
	const idHide = hideIdStack.pop();

	setState(idHide, { vis: false });
	setState(idShow, { vis: true });
};
